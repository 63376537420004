import React, { useState, useEffect, useRef } from 'react';
import { Button } from 'primereact/button';
import { Galleria } from 'primereact/galleria';
import { classNames } from 'primereact/utils';
import { ProgressSpinner } from 'primereact/progressspinner';

const Cumpleanios = () => {
    const [images, setImages] = useState<any>(null);
    const [activeIndex, setActiveIndex] = useState(0);
    const [showThumbnails, setShowThumbnails] = useState(false);
    const [isAutoPlayActive, setAutoPlayActive] = useState(true);
    const [isFullScreen, setFullScreen] = useState(false);
    
    const galleria = useRef(null)

    const responsiveOptions = [
        {
            breakpoint: '1024px',
            numVisible: 5
        },
        {
            breakpoint: '960px',
            numVisible: 4
        },
        {
            breakpoint: '768px',
            numVisible: 3
        },
        {
            breakpoint: '560px',
            numVisible: 1
        }
    ];

    useEffect(() => {
        obtenerFotoPorCategoria('cumpleanios')
        //PhotoService.getImages().then(data => setImages(data));
        bindDocumentListeners();

        return () => unbindDocumentListeners();
    },[]);

    useEffect(() => {//@ts-ignore
        //setAutoPlayActive(galleria.current.isAutoPlayActive())
        
    },[isAutoPlayActive]);

    const obtenerFotoPorCategoria = async (categoria:string) => {
        // Obtener ID de URL
                    // Llamar a la API para obtener los detalles
                    const respuesta = await fetch(`http://www.elregalazocampillo.com/obtenerFotoPorCategoria.php?categoria=${categoria}`);
                    const fotos = await respuesta.json();
                    setImages(fotos)
                    console.log(fotos);
                    console.log(respuesta);
                    
                    
                    // "refrescar" el formulario
            }
            
    const onItemChange = (event) => {
        setActiveIndex(event.index)
    }

    const toggleFullScreen = () => {
        if (isFullScreen) {
            closeFullScreen();
        }
        else {
            openFullScreen();
        }
    }

    const onFullScreenChange = () => {
        setFullScreen(prevState => !prevState )
    }

    const openFullScreen = () => {
        let elem:any = document.querySelector('.custom-galleria');
        if (elem.requestFullscreen) {
            elem.requestFullscreen();
        }
        else if (elem.mozRequestFullScreen) { /* Firefox */
            elem.mozRequestFullScreen();
        }
        else if (elem.webkitRequestFullscreen) { /* Chrome, Safari & Opera */
            elem.webkitRequestFullscreen();
        }
        else if (elem.msRequestFullscreen) { /* IE/Edge */
            elem.msRequestFullscreen();
        }
    }

    const closeFullScreen = () => {
        if (document.exitFullscreen) {
            document.exitFullscreen();
        }//@ts-ignore
        else if (document.mozCancelFullScreen) {document.mozCancelFullScreen();
        }//@ts-ignore
        else if (document.webkitExitFullscreen) {document.webkitExitFullscreen();
        }//@ts-ignore
        else if (document.msExitFullscreen) {document.msExitFullscreen();
        }
    }

    const bindDocumentListeners = () => {
        document.addEventListener("fullscreenchange", onFullScreenChange);
        document.addEventListener("mozfullscreenchange", onFullScreenChange);
        document.addEventListener("webkitfullscreenchange", onFullScreenChange);
        document.addEventListener("msfullscreenchange", onFullScreenChange);
    }

    const unbindDocumentListeners = () => {
        document.removeEventListener("fullscreenchange", onFullScreenChange);
        document.removeEventListener("mozfullscreenchange", onFullScreenChange);
        document.removeEventListener("webkitfullscreenchange", onFullScreenChange);
        document.removeEventListener("msfullscreenchange", onFullScreenChange);
    }

    const thumbnailTemplate = (item) => {
        return (
            <div className="grid grid-nogutter justify-content-center">
                <img src={item.foto} alt={item.titulo} style={{ display: 'block', height: '100px' }} />
            </div>
        );
    }

    const itemTemplate = (item) => {
        if (isFullScreen) {
            return <img src={item.foto} alt={item.titulo} style={{width:'80%', height:'80%'}}/>
        }

        return <img src={item.foto} alt={item.titulo} style={{ width: '100%', display: 'block' }} />
    }

    const renderHeader = () => {
        let fullScreenClassName = classNames('pi', {
            'pi-window-maximize': !isFullScreen,
            'pi-window-minimize': isFullScreen
        });

        return(
            <div className="custom-galleria-footer flex align-items-center">
                <Button icon={fullScreenClassName} onClick={() => toggleFullScreen()} 
                        className="fullscreen-button p-button-secondary p-button-outlined m-1" />
                {
                    images && (
                        <span className="title-container">
                            <span className='mr-2 text-lg'>{`${activeIndex + 1} / ${images.length}`} </span>
                            <span className="title font-bold mr-2 text-md">{images[activeIndex]?.titulo}</span>
                        </span>
                    )
                }
            </div>
        )
    }

    const renderFooter = () => {
        let autoPlayClassName = classNames('pi', {
            'pi-play': !isAutoPlayActive,
            'pi-pause': isAutoPlayActive
        });

        

        return (
            <div className="custom-galleria-footer flex align-items-center">
                <Button className='p-button-secondary p-button-outlined m-1' icon="pi pi-list" onClick={() => setShowThumbnails(prevState => !prevState)} />
                <Button className='p-button-secondary p-button-outlined m-1' icon={autoPlayClassName} onClick={() => {
                    if (!isAutoPlayActive) {//@ts-ignore
                        galleria.current.startSlideShow();
                        setAutoPlayActive(true)
                    }
                    else {//@ts-ignore
                        galleria.current.stopSlideShow();
                        setAutoPlayActive(false)
                    }
                }} />
                
                {
                    images && (
                        <span className="title-container">
                            {/* <span className='mr-2 text-lg'>{activeIndex + 1}/{images.length}</span> */}
                            {/* <span className="title font-bold mr-2 text-md">{images[activeIndex].titulo}</span> */}
                            <span>{images[activeIndex]?.descripcion}</span>
                        </span>
                    )
                }
                
            </div>
        );
    }

    const footer = renderFooter();
    const header = renderHeader();
    const galleriaClassName = classNames('custom-galleria justify-content-center border-1 border-300 shadow-2 border-round-lg px-2 py-1', {
        'fullscreen': isFullScreen
    });

    return (<>
    <div className='text-5xl font-bold text-center mb-3'>¡Cumpleaños feliz, cumpleaños feliz...!</div>
        {/* <div className="card galleria-demo min-w-min"> */}
            <div className=' flex justify-content-center min-w-min'>
            {!!images ? <Galleria ref={galleria} value={images} activeIndex={activeIndex} onItemChange={onItemChange}
                showThumbnails={showThumbnails} showItemNavigators showItemNavigatorsOnHover
                numVisible={5} circular autoPlay transitionInterval={3000} responsiveOptions={responsiveOptions}
                item={itemTemplate} thumbnail={thumbnailTemplate} footer={footer} header={header}
                style={{ maxWidth: '640px' }} className={galleriaClassName} /> : <ProgressSpinner/>}
            </div>
        {/* </div> */}
        </>
    )

}

export default Cumpleanios