import React, { useState, useRef } from "react";
import { Dropdown } from 'primereact/dropdown';
import { Galleria } from 'primereact/galleria';

import { deleteImage } from "../../service/service";
import { AxiosResponse } from "axios";
import { Foto } from "../../schema/schema";
import { ProgressSpinner } from "primereact/progressspinner";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";

const EliminarImagen = ({ asignaMenu }) => {
    const [categoria, setCategoria] = useState('');
    const [images, setImages] = useState<Foto[] | null>([])
    const [deleteImage, setDeleteImage] = useState<Foto | null>(null)
    const [deleteDialog, setDeleteDialog] = useState<boolean>(false)
    const [activeIndex, setActiveIndex] = useState(0);

    const toast = useRef(null);
    const galleria = useRef(null);

    const categorias = [
        { name: 'Ramos y centros', categoria: "RamosCentros" },
        { name: 'Iglesia', categoria: "Iglesia" },
        { name: 'Salón de bodas', categoria: "SalonBodas" },
        { name: 'Casa', categoria: "Casa" },
        { name: 'Comuniones', categoria: "Comuniones" },
        { name: 'Celebraciones', categoria: "Celebraciones" },
        { name: 'San Valentín', categoria: "SanValentin" },
        { name: 'Día de la Madre', categoria: "DiaMadre" },
        { name: 'Cumpleaños', categoria: "Cumpleanios" },
        { name: 'Semana Santa', categoria: "SemanaSanta" },
        { name: 'Romerías y otros', categoria: "Romeria" },
        { name: 'Plantas', categoria: "Plantas" },
        { name: 'Complementos', categoria: "Complementos" },
        { name: 'Otros', categoria: "Otros" }
    ];
    console.log(images);
    const obtenerFotoPorCategoria = async (categoria:string) => {
        // Obtener ID de URL
                    // Llamar a la API para obtener los detalles
                    const respuesta = await fetch(`http://www.elregalazocampillo.com/obtenerFotoPorCategoria.php?categoria=${categoria}`);
                    const fotos = await respuesta.json();
                    setImages(fotos)
                    console.log(fotos);
                    console.log(respuesta);
                    
                    
                    // "refrescar" el formulario
            }

    const eliminarFotografia = async() => {
        const cargaUtil = JSON.stringify(deleteImage?.id);
        // ¡Y enviarlo!
        const respuesta = await fetch(`https://www.elregalazocampillo.com/eliminarFoto.php?id=${deleteImage?.id}`);
        console.log("borrado",respuesta);
        
        setDeleteDialog(false)
            toastSuccess()
            obtenerFotoPorCategoria(categoria)
        
    }

    const toastSuccess = () => {//@ts-ignore
        toast.current.show({ severity: 'success', summary: 'Info', detail: 'Foto borrada' });
    };
    const toastError = () => {//@ts-ignore
        toast.current.show({ severity: 'error', summary: 'Error', detail: 'Error al borrar la foto' });
    };

    const itemTemplateMain = (item) => {
        return <img src={item.file} alt={item.alt} style={{ display: 'block', height: "600px" }} />;
    }

    const thumbnailTemplateMain = (item) => {
        return <img src={item.file} alt={item.alt} style={{ display: 'block', width: "900px" }} />;
    }
    return (
        <div className="flex flex-column">
        <div className="mb-3 flex overflow-hidden  w-full flex-column">
            <div className="flex flex-grow-0 w-full flex-column mb-2">
                <span className="mb-2">Categoría</span>
            </div>
            <div className="flex flex-grow-0 w-full">
                <div className="flex flex-grow-0 w-full">
                    <Dropdown value={categoria} onChange={(e) => {
                        setImages(null)
                        setCategoria(e.value)
                        obtenerFotoPorCategoria(e.value.categoria)
                        
                        // setTimeout(() => {
                        //     setCategoria(e.value.name)
                        // }, 100)

                    }} options={categorias} optionLabel="name"
                        //placeholder="categoría" 
                        className="w-full md:w-14rem" />

                </div>
            </div>
            
            
        </div>
        {!!images ? <div className="card flex justify-content-center">
                <Galleria ref={galleria} value={images} numVisible={7} className="w-10"
                    activeIndex={activeIndex} onItemChange={(e) => setActiveIndex(e.index)}
                    circular fullScreen showItemNavigators showThumbnails={false} item={itemTemplateMain} thumbnail={thumbnailTemplateMain} />
                <div className="grid w-full justify-content-center ml-8">
                    {images.map((imagen: Foto, index: any) => {
                            let imgEl = <img className="mr-3 mb-3" src={imagen.foto} alt={imagen.titulo} style={{ cursor: 'pointer', width: "100px", height: "150px" }}
                                onClick={
                                    () => {
                                        setActiveIndex(index);
                                        setDeleteImage(imagen)
                                        setDeleteDialog(true)
                                        //@ts-ignore
                                        // deleteImage(imagen.id).then((response: AxiosResponse) => {
                                        //     if (response.status === 400) {
                                        //         console.log("No se puede borrar");

                                        //     } else {
                                        //         console.log("borrado");

                                        //     }
                                        // })
                                    }
                                } />
                            return (
                                <div className="col-3" key={index}>
                                    {imgEl}
                                </div>
                            )
                        })
                    }
                </div>
            </div>: <ProgressSpinner/>}
            <Dialog onHide={()=>{
                setDeleteDialog(false)
                setDeleteImage(null)
                }} 
                visible={deleteDialog} 
                header={'Eliminar fotografía'}
                className="w-full xl:w-4 lg:w-6 md:w-8"
                footer={<div className="flex flex-row justify-content-between">
                    <Button className="p-button-secondary" onClick={()=>setDeleteDialog(false)}>Cancelar</Button>
                    <Button className="p-button-danger" onClick={()=>{eliminarFotografia()}}>Eliminar</Button>
                </div>}
            >
                ¿Eliminar fotografia?
            </Dialog>
            <Toast ref={toast}/>
    </div>
    )
}

export default EliminarImagen