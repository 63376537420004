import React from "react";
import { useState,useEffect, useRef } from "react";
import { Galleria } from 'primereact/galleria';

import { Carousel } from 'primereact/carousel';
import axios, { AxiosResponse } from "axios";
import { Button } from "primereact/button";
import { Foto } from "../schema/schema";
import { Link } from "react-router-dom";

const BodyApp = ({imagenes, ren, setRen, obtenerFotografias}) => {

    const [activeIndex, setActiveIndex] = useState(0);
    const [fotografias, setFotografias] = useState()
    const [images, setImages] = useState<any>()    
    const galleria = useRef(null);

    
    const responsiveOptions = [
        {
            breakpoint: '991px',
            numVisible: 4,
            numScroll: 1
        },
        {
            breakpoint: '767px',
            numVisible: 3,
            numScroll: 1
        },
        {
            breakpoint: '575px',
            numVisible: 1,
            numScroll: 1
        }
    ];
    console.log(imagenes);
    useEffect(()=>{
        obtenerFotografias()
    },[])
    // const obtenerFotografias = async ()=> {
    //     const respuesta = await fetch(`https://elregalazocampillo.com/obtenerFotografias.php`)
    //     console.log("RESPUESTA",respuesta);
    //     const fotos = await respuesta.json()
    //     console.log("data",fotos);
    //     setImages(fotos)
    // }
    // useEffect(() => {
    //     PhotoService.getImages().then(data => setImages(data));
    // }, [])
    const itemTemplateMain = (item) => {
        return <img src={item.foto} alt={item.titulo} style={{  display: 'block',height:"600px"  }} />;
    }

    const thumbnailTemplateMain = (item) => {
        return <img src={item.foto} alt={item.titulo} style={{ display: 'block',width:"900px" }} />;
    }

    const itemTemplate = (item) => {
        return <img src={item.foto} alt={item.titulo} style={{width:"100%",height:"500px"}} />
    }

    const thumbnailTemplate = (item) => {
        return <img src={item.foto} alt={item.titulo} style={{width:"50px",height:"25px"}}/>
    }
    const productTemplate = (product:Foto) => {
        return (
            <div className="border-1 border-50 shadow-2 border-round-lg m-2 text-center py-5 px-3 h-20rem " style={{minHeight:'500px'}}>
                <h4 className="mb-1">{product.titulo}</h4>
                <div className="mb-3">
                    <img src={`${product.foto}`} alt={product.titulo} className="w-6 shadow-2" style={{maxHeight:'300px'}}
                    // onClick={() => {; 
                    //             //@ts-ignore
                    //             galleria.current.show()}}
                                />
                    
                </div>
                <div>
                    
                    <h6 className="mt-0 mb-3">{product.descripcion}</h6>
                    
                    {/* <div className="mt-5 flex flex-wrap gap-2 justify-content-center">
                        <Button icon="pi pi-search" className="p-button p-button-rounded" />
                        <Button icon="pi pi-star-fill" className="p-button-success p-button-rounded" />
                    </div> */}
                </div>
            </div>
        );
    };
    return (
        <div className="border-bottom-1 border-200 p-2">
            
            <div>
                {!!images?.length? <>
                    {/* <div className="card flex justify-content-center mt-3">
                        <Galleria  responsiveOptions={responsiveOptions} 
                                    value={imagenes} 
                                    numVisible={5} 
                                    style={{ maxWidth: '640px' }} 
                                    item={itemTemplate} 
                                    thumbnail={thumbnailTemplate} />
                    </div> */}
                        
                
                </>: <>

                {/* {!!imagenes && <div className="card flex justify-content-center mt-4">
            <Galleria ref={galleria} value={imagenes} numVisible={6} className="w-10 mt-4" 
            activeIndex={activeIndex} onItemChange={(e) => setActiveIndex(e.index)}
            circular fullScreen showItemNavigators showThumbnails={false} item={itemTemplateMain} thumbnail={thumbnailTemplateMain} />
            <div className="grid w-6 justify-content-center ml-8 md:ml-5 sm:ml-2">
                {imagenes.map((imagen:any, index:any) => {
                        let imgEl = <img className="mr-3 mb-3" src={imagen.foto} alt={imagen.titulo} style={{ cursor: 'pointer',width:"100px",height:"150px"  }} onClick={
                            () => {setActiveIndex(index); 
                                //@ts-ignore
                                galleria.current.show()}
                        } />
                        return (
                            <div className="col-3" key={index}>
                                {imgEl}
                            </div>
                        )
                    })
                }
            </div>
        </div>} */}
         <div className="card">
            <Carousel value={imagenes} numVisible={3} numScroll={3} responsiveOptions={responsiveOptions} className=" border-200" circular
            autoplayInterval={3000} itemTemplate={productTemplate} />
        </div>
                
                </>}
    

         
            </div>
        </div>
    )
}

export default BodyApp