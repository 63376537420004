import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
//import "primereact/resources/themes/lara-light-indigo/theme.css";
//import "primereact/resources/themes/arya-green/theme.css"
import "primereact/resources/themes/vela-green/theme.css"
//import "primereact/resources/themes/luna-green/theme.css"  //para tema oscuro
import "primereact/resources/primereact.min.css";
import 'primeicons/primeicons.css';
import { BrowserRouter } from 'react-router-dom';
import axios from 'axios';

// get the client
// const mysql = require('mysql2');

// create the connection to database
// const connection = mysql.createConnection({
//   host: 'elregalazocampillo.com',
//   user: 'u508643963_admin',
//   database: 'u508643963_floristeria'
// });

// execute will internally call prepare and query
// connection.execute(
//   'SELECT * FROM `wp_users` WHERE `user_login` = ? AND `user_email` > ?',
//   ['fabipaulano', 'fabiopaulano@gmail.com'],
//   function(err, results, fields) {
//     console.log(results); // results contains rows returned by server
//     console.log(fields); // fields contains extra meta data about results, if available

//     // If you execute same statement again, it will be picked from a LRU cache
//     // which will save query preparation time and give better performance
//   }
// );

const root = ReactDOM.createRoot(document.getElementById('root'));

axios.defaults.withCredentials = false

root.render(
  // <React.StrictMode>
  <BrowserRouter>
    <App />
  </BrowserRouter>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
