
import React from "react";
import { useEffect,useState } from "react";
import logo from "./logo.svg";
import "./App.css";
import HeaderApp from "./header/HeaderApp.tsx";
import FooterApp from "./footer/FooterApp.tsx";
import BodyApp from "./body/BodyApp.tsx";
import axios from 'axios';
import logotipo from './logotipo2.png'
import { Route, Routes, Link } from 'react-router-dom';
import { InputText } from "primereact/inputtext";
import Iglesia from "./body/pages/Iglesia.tsx";
import RoutesApp from "./routes/RoutesApp.tsx";
import { Button } from "primereact/button";


const service = require('./service/service')

function App() {
  const [imagenes, setImagenes] = useState(null);
  const [images, setImages] = useState(null);
  const [ren, setRen] = useState()

  
  useEffect(()=>{
    
  //   service.getAll().then(res => {
  //   setImagenes(res.data.data)
  //   console.log(res);
  // })
  obtenerFotografias()
  
  },[ren])

  const obtenerFotografias = async () => {
        const respuesta = await fetch(`https://elregalazocampillo.com/obtenerFotografias.php`);
        const fotos = await respuesta.json();
        setImagenes(fotos)
  }
  // const asignaMenu = (categoria) => {
  //   let imagesAgignadas = []

  //   !!imagenes && imagenes.map((img)=>{
  //     if (img.categoria === categoria){
  //       imagesAgignadas.push(img)
  //     }
      
  //     setImages(imagesAgignadas)
  //   })

  // }
 
  // axios.get('http://localhost:3000/user').then((res)=>{
    
  //   console.log(res);
  // })

  // fetch('http://localhost:3000/user').then((res)=>{
  //   console.log(res);
  // })
  // useEffect(() => {
  //   fetch("http://localhost:3001/api")
  //     .then((res) => res.json())
  //     .then((data) => setData(data.message));
  //     console.log(data.message);
  // }, []);

// console.log(data);

  // return (
  //   <div className="App">
  //     <header className="App-header">
  //       <img src={logo} className="App-logo" alt="logo" />
  //       <p>{!data ? "Loading..." : data}</p>
  //     </header>
  //   </div>
  // );
  



  return (
      <>
        <HeaderApp images={images}
                   logotipo={logotipo}
                   />
                   
        <RoutesApp imagenes={imagenes} ren={ren} setRen={setRen} obtenerFotografias={obtenerFotografias}/>
        {/* <BodyApp imagenes={imagenes} images={images} ren={ren} serRen={setRen}/> */}
        <FooterApp />
        
      </>
  )
}

export default App;