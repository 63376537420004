import React from "react";
import { Link } from "react-router-dom";

const FooterApp = ({asignaMenu}) => {

    return(
        <div className="flex flex-row justify-content-between h-10rem w-full absolute ">
            <div className="flex flex-wrap w-6 mt-2 justify-content-between">
                <div className="flex flex-column ">
                    <ul className="list-none line-height-3">
                        <li><Link to={'/iglesia'} className="cursor-pointer no-underline text-primary-500 hover:text-primary-800" >Iglesia</Link></li>
                        <li><Link to={"/casa"} className="mb-1 cursor-pointer no-underline text-primary-500 hover:text-primary-800">Casa</Link></li>
                        <li><Link to={"/salonBodas"} className="mb-1 cursor-pointer no-underline text-primary-500 hover:text-primary-800">Salón de bodas</Link></li>
                        <li><Link to={"/cumpleanios"} className="cursor-pointer no-underline text-primary-500 hover:text-primary-800">Cumpleaños</Link></li>
                    </ul>
                </div>
                <div className="flex flex-column ">
                    <ul className="list-none line-height-3">
                        <li><Link to={"/ramosCentros"} className="cursor-pointer no-underline text-primary-500 hover:text-primary-800">Ramos y centros</Link></li>
                        <li><Link to={"/comuniones"} className="mb-2 cursor-pointer no-underline text-primary-500 hover:text-primary-800">Comuniones</Link></li>
                        <li><Link to={"/celebraciones"} className="mb-2 cursor-pointer no-underline text-primary-500 hover:text-primary-800">Celebraciones</Link></li>
                        <li><Link to={"/diaMadre"} className="cursor-pointer no-underline text-primary-500 hover:text-primary-800">Día de la madre</Link></li>
                    </ul>
                </div>
                <div className="flex flex-column mr-1">
                    <ul className="list-none line-height-3">
                        <li><Link to={"/sanValentin"} className="mb-3 cursor-pointer no-underline text-primary-500 hover:text-primary-800">San Valentin</Link></li>
                        <li><Link to={"/semanaSanta"} className="mb-3 cursor-pointer no-underline text-primary-500 hover:text-primary-800">Semana Santa</Link></li>
                        <li><Link to={"/otros"} className="mb-3 cursor-pointer no-underline text-primary-500 hover:text-primary-800">Otros</Link></li>
                        <li><Link to={"/"} className="cursor-pointer no-underline text-primary-500 hover:text-primary-800">Inicio</Link></li>
                    </ul>
                </div>
            </div>
            <div className="flex flex-wrap w-6 justify-content-between flex-row">
                <div className="flex flex-column w-4 border-left-1 border-200 pl-2 mt-2">
                    <p className="ml-3 text-xl">Contáctanos</p>
                    <span className="ml-3 cursor-pointer p-1 hover:surface-200 border-round-md">
                        <i className="pi pi-instagram text-xl mr-2 text-purple-500"/>floristeriaelregalazo
                    </span>
                    <span className="ml-3 cursor-pointer p-1 hover:surface-200 border-round-md">
                        <i className="pi pi-facebook text-xl mr-2 text-blue-500"/>floristeria el regalazo
                    </span>
                     <span className="ml-3 cursor-pointer p-1 hover:surface-200 border-round-md">
                        <i className="pi pi-whatsapp text-xl mr-2 text-green-500"/> 622 226 622
                    </span>
                </div>
                <div className="flex justify-content-center flex-column align-items-center w-4">
                    <div className="text-xl my-3">
                        Encuéntranos
                    </div>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d717.6118614419115!2d-3.6353307840181754!3d37.555994451812744!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd6e0ceece0478bd%3A0x3a897745a974c1ee!2sEl%20Regalazo!5e0!3m2!1ses!2ses!4v1685884967661!5m2!1ses!2ses"
                        width="200"
                        height="100"
                        loading="lazy">
                    </iframe>
                </div>
            </div>    
        </div>
    )
}

export default FooterApp