import React, { useState } from "react";
import { Menubar } from 'primereact/menubar';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Password } from 'primereact/password';
import {Sidebar} from 'primereact/sidebar'
//@ts-ignore
import AdministracionMenu from "./Administracion/AdministracionMenu.tsx";
import { Link } from "react-router-dom";


const HeaderApp = ({images,logotipo}) => {
    const [admin, setAdmin] = useState<any>()
    const [asignaMenu, setAsignaMenu] = useState<any>()
    const [visible, setVisible] = useState(false);
    const [usuario,setUsuario] = useState<any>()
    const [pass,setPass] = useState<any>()
    const [error,setError] = useState<any>(null)

    const items = [
        {
            label: 'Bodas',
            //icon: 'pi pi-fw pi-file',
            items: [
                
                {
                    template:()=>{return <Link className="no-underline" to="/ramosCentros">
                        <Button className="text-800 hover:text-primary-600 hover:bg-primary-50 w-full b-button-secondary p-button-text border-round-none">
                            Ramos y centros
                            </Button>
                        </Link>}
    
                },
                {
                    template:()=>{return <Link className="no-underline" to="/iglesia">
                        <Button className="text-800 hover:text-primary-600 hover:bg-primary-50 w-full b-button-secondary p-button-text border-round-none">
                            Iglesia
                            </Button>
                        </Link>}
                },
                {
                    template:()=>{return <Link className="no-underline" to="/salonBodas">
                        <Button className="text-800 hover:text-primary-600 hover:bg-primary-50 w-full b-button-secondary p-button-text border-round-none">
                            Salón de bodas
                            </Button>
                        </Link>}
                },
                {
                    template:()=>{return <Link className="no-underline" to="/casa">
                        <Button className="text-800 hover:text-primary-600 hover:bg-primary-50 w-full b-button-secondary p-button-text border-round-none">
                            Casa
                            </Button>
                        </Link>}
                }
            ]
        },
        {
            label: 'Iglesia',
            //icon: 'pi pi-fw pi-pencil',
            items: [
                {
                    label: 'Eventos',
                    //icon: 'pi pi-fw pi-align-left'
                    items: [
                        {
                            template:()=>{return <Link className="no-underline" to="/comuniones">
                                <Button className="text-800 hover:text-primary-600 hover:bg-primary-50 w-full b-button-secondary p-button-text border-round-none">
                                    Comuniones
                                </Button>
                            </Link>}
                        },
                        {
                            template:()=>{return <Link className="no-underline" to="/celebraciones">
                                <Button className="text-800 hover:text-primary-600 hover:bg-primary-50 w-full b-button-secondary p-button-text border-round-none">
                                    Celebraciones
                                </Button>
                            </Link>}
                        },
                    ]
                },
                {
                    template:()=>{return <Link className="no-underline" to="/otros">
                                <Button className="text-800 hover:text-primary-600 hover:bg-primary-50 w-full b-button-secondary p-button-text border-round-none">
                                    Otros
                                </Button>
                            </Link>}
                },
            ]
        },
        {
            label: 'Fechas especiales',
            // icon: 'pi pi-fw pi-user',
            items: [
                {
                    template:()=>{return <Link className="no-underline" to="/sanValentin">
                                <Button className="text-800 hover:text-primary-600 hover:bg-primary-50 w-full b-button-secondary p-button-text border-round-none">
                                    San Valentín
                                </Button>
                            </Link>}
    
                },
                {
                    template:()=>{return <Link className="no-underline" to="/diaMadre">
                                <Button className="text-800 hover:text-primary-600 hover:bg-primary-50 w-full b-button-secondary p-button-text border-round-none">
                                    Día de la Madre
                                </Button>
                            </Link>}
    
                },
                {
                    template:()=>{return <Link className="no-underline" to="/cumplanios">
                                <Button className="text-800 hover:text-primary-600 hover:bg-primary-50 w-full b-button-secondary p-button-text border-round-none">
                                    Cumpleaños
                                </Button>
                            </Link>}
    
                }
            ]
        },
        {
            label: 'Pasos',
            // icon: 'pi pi-fw pi-calendar',
            items: [
                {
                    template:()=>{return <Link className="no-underline" to="/semanaSanta">
                                <Button className="text-800 hover:text-primary-600 hover:bg-primary-50 w-full b-button-secondary p-button-text border-round-none">
                                    Semana Santa
                                </Button>
                            </Link>}
    
                }, 
                {
                    template:()=>{return <Link className="no-underline" to="/romeria">
                                <Button className="text-800 hover:text-primary-600 hover:bg-primary-50 w-full b-button-secondary p-button-text border-round-none">
                                    Romerias
                                </Button>
                            </Link>}
    
                },
    
            ]
        },
        {
            label: 'Otros',
            items: [
                {
                    template:()=>{return <Link className="no-underline" to="/planta">
                                        <Button className="text-800 hover:text-primary-600 hover:bg-primary-50 w-full b-button-secondary p-button-text border-round-none">
                                            Plantas
                                        </Button>
                                    </Link>}
                },
                {
                    template:()=>{return <Link className="no-underline" to="/complementos">
                                        <Button className="text-800 hover:text-primary-600 hover:bg-primary-50 w-full b-button-secondary p-button-text border-round-none">
                                            Complementos
                                        </Button>
                                    </Link>}
                },
            ]
        },
        
        
    ];

    const obtenerUsuario = async () => {
        // Obtener ID de URL
                    // Llamar a la API para obtener los detalles
                    
                    
                    const respuesta = await fetch(`http://www.elregalazocampillo.com/obtenerUsuario.php?name=${usuario}&pass=${pass}`);
                    const user = await respuesta.json();

                    if (user !== false){
                        setAdmin(true)
                        setError(null)
                    }else{
                        setError('Usuario o contraseña incorrectos')
                    } 
                    
                    
                    
                    // "refrescar" el formulario
            }

    return (
        <>
            <div className="flex justify-content-between">
                <div className="w-7 cursor-pointer" onClick={()=>asignaMenu("")}>
                    <Link to="/"><img src={logotipo} alt="Logotipo El Regalazo" className="w-full" height="150px"/></Link>
                </div>
                <div className="flex-none flex flex-column mt-2 mr-2 mb-2">
                    {!admin && <>
                    <div className="mb-1">
                        <span className="p-input-icon-right">
                            <InputText placeholder="Usuario" className="p-inputtext-sm -mr-2 w-11" onChange={(e)=>setUsuario(e.target.value)}/>
                            <i className="pi pi-user mr-3"/>
                        </span>
                    </div>
                    <div className="mb-1">
                        <span className="p-input-icon-left">
                            <i className="pi pi-key" />
                            <Password placeholder="Contraseña" feedback={false} className="p-inputtext-sm w-full" onChange={(e)=>setPass(e.target.value)}/>
                            
                        </span>
                    </div>
                    <div className="mb-1">
                        <Button className="p-button-secondary" type="button" label="Login" onClick={(e)=>{
                            obtenerUsuario()
                            }}></Button>
                    </div>
                    
                    </>}
                    {admin && <Button className="mt-3 mr-3 p-button-secondary p-button-text" type="button" label="Administrar" onClick={(e)=>setVisible(true)}/>}
                    {admin && <Button className="mt-2 mr-3 p-button-secondary p-button-outlined" type="button" label="Cerrar Sesión" onClick={()=>{
                        setAdmin(false)
                        setPass(null)
                        setUsuario(null)
                    }}/>}
                    {!!error && <span className="text-red-500">{error}</span>}
                </div>
            </div>
            
            <div className="flex flex-row justify-content-around mb-2 pt-2 border-top-1 border-200">
                <span className="cursor-pointer p-1 hover:surface-200 border-round-md">
                    <i className="pi pi-instagram text-xl mr-2 text-purple-500"/>floristeriaelregalazo
                </span>
                <span className="cursor-pointer p-1 hover:surface-200 border-round-md">
                    <i className="pi pi-facebook text-xl mr-2 text-blue-500"/>floristeria el regalazo
                </span>
                <span className="cursor-pointer p-1 hover:surface-200 border-round-md">
                    <i className="pi pi-whatsapp text-xl mr-2 text-green-500"/> 622 226 622
                </span>
            </div>
            <div>
                <Menubar model={items}></Menubar>
            </div>
            <Sidebar visible={visible} onHide={() => setVisible(false)} position="right" className="w-5" closeOnEscape>
                <AdministracionMenu asignaMenu={asignaMenu} images={images}/>
            </Sidebar>
            
        </>
    )
}

export default HeaderApp