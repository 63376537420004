import React, { useState,useRef, useEffect } from "react";
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dialog } from 'primereact/dialog'
import { ProgressSpinner } from 'primereact/progressspinner';
import { Galleria } from 'primereact/galleria';
import { Button } from "primereact/button";
import { Foto } from "../../schema/schema";//@ts-ignore
import EditDialog from "./administracionDialog/EditDialog.tsx";

const EditarImagen = ({ //asignaMenu, //images 
}) => {
    const [categoria, setCategoria] = useState(null);
    const [activeIndex, setActiveIndex] = useState(0);
    const [images, setImages] = useState<Foto[] | null>([])
    const [editImage, setEditImage] = useState<Foto | null>(null)
    const [editDialog, setEditDialog] = useState<boolean>(false)
    const galleria = useRef(null);

    
    useEffect(()=>{
        
    },[])

    const obtenerFotoPorCategoria = async (categoria:string) => {
// Obtener ID de URL
            // Llamar a la API para obtener los detalles
            const respuesta = await fetch(`http://www.elregalazocampillo.com/obtenerFotoPorCategoria.php?categoria=${categoria}`);
            const fotos = await respuesta.json();
            setImages(fotos)
            console.log(fotos);
            console.log(respuesta);
            
            
            // "refrescar" el formulario
    }

    const categorias = [
        { name: 'Ramos y centros', categoria: "ramosCentros" },
        { name: 'Iglesia', categoria: "iglesia" },
        { name: 'Salón de bodas', categoria: "salonBodas" },
        { name: 'Casa', categoria: "casa" },
        { name: 'Comuniones', categoria: "comuniones" },
        { name: 'Celebraciones', categoria: "celebraciones" },
        { name: 'San Valentín', categoria: "sanValentin" },
        { name: 'Día de la Madre', categoria: "diaMadre" },
        { name: 'Cumpleaños', categoria: "cumpleanios" },
        { name: 'Semana Santa', categoria: "semanaSanta" },
        { name: 'Romerías y otros', categoria: "romeria" },
        { name: 'Plantas', categoria: "plantas" },
        { name: 'Complementos', categoria: "complementos" },
        { name: 'Otros', categoria: "otros" }
    ];

    const itemTemplateMain = (item) => {
        return <img src={item.foto} alt={item.titulo} style={{ display: 'block', height: "600px" }} />;
    }

    const thumbnailTemplateMain = (item) => {
        return <img src={item.foto} alt={item.titulo} style={{ display: 'block', width: "900px" }} />;
    }

    return (

        <div className="flex flex-column">
            <div className="mb-3 flex overflow-hidden  w-full flex-column">
                <div className="flex flex-grow-0 w-full flex-column mb-2">
                    <span className="mb-2">Categoría</span>
                </div>
                <div className="flex flex-grow-0 w-full">
                    <div className="flex flex-grow-0 w-full">
                        <Dropdown value={categoria} onChange={(e) => {
                            setImages(null)
                            setCategoria(e.value)
                            obtenerFotoPorCategoria(e.value.categoria)
                            
                            // setTimeout(() => {
                            //     setCategoria(e.value.name)
                            // }, 100)

                        }} options={categorias} optionLabel="name"
                            //placeholder="categoría" 
                            className="w-full md:w-14rem" />

                    </div>
                </div>
                
                
            </div>
            {!!images ? <div className="card flex justify-content-center">
                    <Galleria ref={galleria} value={images} numVisible={7} className="w-10"
                        activeIndex={activeIndex} onItemChange={(e) => setActiveIndex(e.index)}
                        circular fullScreen showItemNavigators showThumbnails={false} item={itemTemplateMain} thumbnail={thumbnailTemplateMain} />
                    <div className="grid w-full justify-content-center ml-8">
                        {images.map((imagen: Foto, index: any) => {
                                let imgEl = <img className="mr-3 mb-3" src={imagen.foto} alt={imagen.titulo} style={{ cursor: 'pointer', width: "100px", height: "150px" }}
                                    onClick={
                                        () => {
                                            setActiveIndex(index);
                                            setEditImage(imagen)
                                            setEditDialog(true)
                                            //@ts-ignore
                                            // deleteImage(imagen.id).then((response: AxiosResponse) => {
                                            //     if (response.status === 400) {
                                            //         console.log("No se puede borrar");

                                            //     } else {
                                            //         console.log("borrado");

                                            //     }
                                            // })
                                        }
                                    } />
                                return (
                                    <div className="col-3" key={index}>
                                        {imgEl}
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>: <ProgressSpinner/>}
                <Dialog onHide={()=>{
                    setEditDialog(false)
                    setEditImage(null)
                    }} 
                    visible={editDialog} 
                    header={'Editar fotografía'}
                    className="w-full xl:w-4 lg:w-6 md:w-8"
                >
                    <EditDialog foto={editImage} categorias={categorias} setEditDialog={setEditDialog}/> 
                </Dialog>
            
        </div>

    )
}

export default EditarImagen