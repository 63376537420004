import axios from "axios";
import { environment } from "../environment/environment";

const getAll = () => {
    return axios.get(environment.endpoint)
}

const getOne = (imageId) => {
    return axios.get(environment.endpoint+'/'+imageId)
}

const getUser = (userName,userPass) => {
    return axios.get(environment.endpoint+'/',userName,userPass)
}

const edit = (imageId,body) => {
    return axios.patch(environment.endpoint+'/'+imageId,body)
}
const upload = (body) =>{
    return axios.post(environment.endpoint+'/',body)
}

const deleteImage = (imageId) => {
    return axios.delete(environment.endpoint+'/'+imageId)
}


export {
    getAll,
    getOne,
    getUser,
    edit,
    upload,
    deleteImage
}