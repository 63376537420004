import React from "react";
import { Routes, Route } from "react-router-dom";//@ts-ignore
import BodyApp from "../body/BodyApp.tsx";//@ts-ignore
import Casa from "../body/pages/Casa.tsx";//@ts-ignore
import Celebraciones from "../body/pages/Celebraciones.tsx"//@ts-ignore
import Complementos from "../body/pages/Complementos.tsx"//@ts-ignore
import Comuniones from "../body/pages/Comuniones.tsx"//@ts-ignore
import Cumpleanios from "../body/pages/Cumpleanios.tsx";//@ts-ignore
import DiaMadre from "../body/pages/DiaMadre.tsx";//@ts-ignore
import Iglesia from "../body/pages/Iglesia.tsx";//@ts-ignore
import Otros from "../body/pages/Otros.tsx";//@ts-ignore
import Planta from "../body/pages/Planta.tsx";//@ts-ignore
import RamosCentros from "../body/pages/RamosCentros.tsx";//@ts-ignore
import Romeria from "../body/pages/Romeria.tsx";//@ts-ignore
import SalonBodas from "../body/pages/SalonBodas.tsx";//@ts-ignore
import SanValentin from "../body/pages/SanValentin.tsx";//@ts-ignore
import SemanaSanta from "../body/pages/SemanaSanta.tsx";

const RoutesApp = ({imagenes, ren, setRen, obtenerFotografias}) => {
    return(
        <Routes>
        <Route path="/" element={<BodyApp imagenes={imagenes}  ren={ren} setRen={setRen} obtenerFotografias={obtenerFotografias}/> } />
        <Route path="/iglesia" element={<Iglesia/>}/>
        <Route path="/casa" element={<Casa/>}/>
        <Route path="/celebraciones" element={<Celebraciones/>}/>
        <Route path="/complementos" element={<Complementos/>}/>
        <Route path="/comuniones" element={<Comuniones/>}/>
        <Route path="/cumplanios" element={<Cumpleanios/>}/>
        <Route path="/diaMadre" element={<DiaMadre/>}/>
        <Route path="/otros" element={<Otros/>}/>
        <Route path="/planta" element={<Planta/>}/>
        <Route path="/ramosCentros" element={<RamosCentros/>}/>
        <Route path="/romeria" element={<Romeria/>}/>
        <Route path="/salonBodas" element={<SalonBodas/>}/>
        <Route path="/sanValentin" element={<SanValentin/>}/>
        <Route path="/semanaSanta" element={<SemanaSanta/>}/>
      </Routes>
    )
} 

export default RoutesApp