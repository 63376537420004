import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Toast } from "primereact/toast";
import React, { useEffect, useRef, useState } from "react";
import { Foto } from "../../../schema/schema";

interface EditDialogProps{
    foto:Foto | null
    categorias:{name:string,categoria:string}[]
    setEditDialog: (editDialog:boolean) => void
}

const EditDialog = ({foto, categorias, setEditDialog}:EditDialogProps) => {

    
    const [categoria, setCategoria] = useState(foto?.categoria);
    const [categoriaEdit,setCategoriaEdit] = useState<any>(foto?.categoria)
    const [valueText, setValueText] = useState(foto?.titulo);
    const [valueArea, setValueArea] = useState(foto?.descripcion);

    const editToast = useRef(null);

    useEffect(()=>{
        setCategoria(foto?.categoria)
    },[])
    const editarFoto = async (foto:Foto) => {
        const cargaUtil = JSON.stringify(foto);
        // ¡Y enviarlo!
        const respuesta = await fetch(`https://www.elregalazocampillo.com/editarFoto.php`, {
            method: "PUT",
            body: cargaUtil,
        });
        const exitoso = await respuesta.json();
        console.log("esxitoso",exitoso);
        
        
        if (exitoso){
            console.log("siii");
            toastSuccess()    
            setTimeout(()=>{
                setEditDialog(false)
            },1000)
            
            
        }else{
            toastError()
        }
        
        console.log(respuesta);
        
    }

    const toastSuccess = () => {//@ts-ignore
        editToast.current.show({ severity: 'success', summary: 'Info', detail: 'Foto editada', life:3000 });
    };
    const toastError = () => {//@ts-ignore
        editToast.current.show({ severity: 'error', summary: 'Error', detail: 'Error al editar la foto', life:3000 });
    };

    return(
        <>
        {!!categoria && !!valueText && !!valueArea && <>
            <div className="flex flex-grow-0 w-full mt-3">
                        <div className="flex flex-grow-0 w-full flex-column mb-2">
                            <div className="flex flex-row justify-content-between">
                        <div className="flex flex-grow-0 w-full flex-column">
                            <div className="mb-2">Categoría</div>
                            <div className="flex flex-grow-0 w-full flex-column mb-2">
                            <Dropdown value={categoria} onChange={(e) => {
                            setCategoria(e.value)
                            setCategoriaEdit(e.value.categoria)
                            }} 
                            options={categorias} 
                            optionLabel="name"
                            placeholder={foto?.categoria} 
                            className="w-full md:w-14rem" 
                            />
                        </div>
                        </div>
                        <div>
                            <img src={foto?.foto} style={{  width: "100px", height: "150px" }}/>
                        </div>
                        </div>
                        
                        </div>
                        </div>
                        <div className="mb-3 flex overflow-hidden  w-full flex-column">
                            <div className="flex flex-grow-0 w-full">
                                <span className="mb-2 mt-2">Titulo</span>
                            </div>
                            <div className="flex flex-grow-0 w-full"></div>
                            <InputText value={valueText} onChange={(e) => setValueText(e.target.value)} />
                        </div>
                        <div className="mb-3 flex overflow-hidden  w-full flex-column">
                            <div className="flex flex-grow-0 w-full">
                                <span className="mb-2">Descripción</span>
                            </div>
                            <div className="flex flex-grow-0 w-full"></div>
                            <InputTextarea autoResize value={valueArea} onChange={(e) => setValueArea(e.target.value)} rows={5} className="w-full h-10rem" />
                        </div>
                        <div className="flex justify-content-between">
                            <Button className="p-button-secondary" label="Cancelar" onClick={()=>setEditDialog(false)}/>
                            <Button label="Editar"
                                    className="p-button-success" 
                                    onClick={()=>{
                                let foto_:Foto = {titulo:valueText, categoria:categoriaEdit,descripcion:valueArea, id:foto?.id}
                                editarFoto(foto_)
                            }}
                            disabled={!categoria || !valueArea || !valueText}
                            />
                        </div>
                        <Toast ref={editToast}/>
                    </>}
        </>
    )
}

export default EditDialog