import React from "react";
import { TabView, TabPanel } from 'primereact/tabview';
//@ts-ignore
import AniadirImagen from "./AniadirImagen.tsx";
//@ts-ignore
import EditarImagen from "./EditarImagen.tsx";
//@ts-ignore
import EliminarImagen from "./EliminarImagen.tsx";

const AdministracionMenu = ({asignaMenu,images}) => {

    return(
        <>
        <h3>Menu de Administración</h3>
        <TabView>
                <TabPanel header=" Añadir imagenes" leftIcon="pi pi-plus" className="ml-2">
                    <AniadirImagen/>
                </TabPanel>
                <TabPanel header=" Modificar imagenes" leftIcon="pi pi-pencil">
                    <EditarImagen asignaMenu={asignaMenu} images={images}/>
                </TabPanel>
                <TabPanel header=" Eliminar imagenes" leftIcon="pi pi-times">
                    <EliminarImagen asignaMenu={asignaMenu} images={images}/>
                </TabPanel>
                
            </TabView>
        </>
    )
}

export default AdministracionMenu