import React, { useRef, useState } from "react";
import { FileUpload } from 'primereact/fileupload';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Toast } from 'primereact/toast';
import { upload } from "../../service/service";

const AniadirImagen = () => {
    const [categoria, setCategoria] = useState(null);
    const [categoriaAdd, setCategoriaAdd] = useState()
    const [valueText, setValueText] = useState('');
    const [valueArea, setValueArea] = useState('');
    const [resetFileUpload,setResetFileUpload] = useState(true)
    const toast = useRef(null);

    const categorias = [
        { name: 'Ramos y centros',categoria:"RamosCentros"},
        { name: 'Iglesia',categoria:"Iglesia"},
        { name: 'Salón de bodas',categoria:"SalonBodas"},
        { name: 'Casa',categoria:"Casa"},
        { name: 'Comuniones',categoria:"Comuniones"},
        { name: 'Celebraciones',categoria:"Celebraciones"},
        { name: 'San Valentín',categoria:"SanValentin"},
        { name: 'Día de la Madre',categoria:"DiaMadre"},
        { name: 'Cumpleaños',categoria:"Cumpleanios"},
        { name: 'Semana Santa',categoria:"SemanaSanta"},
        { name: 'Romerías y otros',categoria:"Romeria"},
        { name: 'Plantas',categoria:"Plantas"},
        { name: 'Complementos',categoria:"Complementos"},
        { name: 'Otros',categoria:"Otros"}
    ];
    
    const customBase64Uploader = async (event:any) => {
        setResetFileUpload(false)
        console.log(event.files);
        
        const file = event.files[0]
            const reader = new FileReader();
            let blob = await fetch(file.objectURL).then(r => r.blob()); //blob:url
            reader.readAsDataURL(blob);
             reader.onloadend = async () => {
                const base64data = reader.result;
                
                //@ts-ignore
                let uploadFile = {descripcion:valueArea, foto:base64data, categoria:categoriaAdd, titulo:valueText}
                console.log(uploadFile);

                const cargaUtil = JSON.stringify(uploadFile);

                const respuesta = await fetch(`https://elregalazocampillo.com/crearFoto.php`, {
                    method: "POST",
                    body: cargaUtil,
                });
                const exitoso = await respuesta.json();

                if (exitoso){
                    toastSuccess()
                    setCategoria(null)
                    setValueArea("")
                    setValueText("")
                    setResetFileUpload(true)
                }else{
                    toastError()
                }
                console.log("respuesta",respuesta);
                
                console.log("exitoso",exitoso);
                
                
                // upload(uploadFile).then((response) => {
                //     console.log("entra");
                    
                //     if (response.status === 201) {
                //         console.log("SEND _> 200", response.data);
                //     } else {
                //         console.log("ERROR -> 400", response.data);
                //     }
                // })
            }
    }
    const toastSuccess = () => {//@ts-ignore
        toast.current.show({ severity: 'success', summary: 'Info', detail: 'Foto subida' });
    };
    const toastError = () => {//@ts-ignore
        toast.current.show({ severity: 'error', summary: 'Error', detail: 'Error al subir la foto' });
    };

    return (
        <div className="flex flex-row">
            {/* <form action="crearFoto.php" method="POST" encType="multipart/form-data"> */}
            <div className="mb-3 flex overflow-hidden  w-full flex-column">
                <div className="flex flex-grow-0 w-full flex-column mb-2">
                    <span className="mb-2">Categoría</span>
                </div>
                <div className="flex flex-grow-0 w-full">
                    <div className="flex flex-grow-0 w-full">
                        <Dropdown value={categoria} name="categoria" onChange={(e) => {
                            setCategoria(e.value)
                            setCategoriaAdd(e.value.categoria)
                        }} options={categorias} optionLabel="name"
                            placeholder="categoría" className="w-full md:w-14rem" />

                    </div>
                </div>
                <div className="mb-3 flex overflow-hidden  w-full flex-column">
                    <div className="flex flex-grow-0 w-full">
                        <span className="mb-2 mt-2">Titulo</span>
                    </div>
                    <div className="flex flex-grow-0 w-full"></div>
                    <InputText value={valueText} name="titulo" onChange={(e) => setValueText(e.target.value)} />
                </div>
                <div className="mb-3 flex overflow-hidden  w-full flex-column">
                    <div className="flex flex-grow-0 w-full">
                        <span className="mb-2">Descripción</span>
                    </div>
                    <div className="flex flex-grow-0 w-full"></div>
                    <InputTextarea autoResize name="descripcion" value={valueArea} onChange={(e) => setValueArea(e.target.value)} rows={5} className="w-full h-10rem" />
                </div>
                <div className="card">
                    {/* <input type="file" name="foto"/> */}
                {resetFileUpload && <FileUpload 
                        name="photo[]" 
                        accept="image/*"
                        chooseLabel="Añadir imagen"
                        uploadLabel="Subir"
                        cancelLabel="Cancelar"
                        customUpload
                        uploadHandler={customBase64Uploader}
                        maxFileSize={10000000} 
                        emptyTemplate={<p className="m-0">Arrastre aqui la imagen...</p>} 
                        disabled={!categoria || !valueArea || !valueText}
                        />}
            </div>
            </div>
            <button type="submit" onClick={()=>{}}/>
            <Toast ref={toast}/>
            {/* </form> */}
        </div>
    )
}

export default AniadirImagen